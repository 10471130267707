import React, { useState, useEffect } from "react";
import CommonBreadcrumbs from "../../../common/CommonBreadcrumbs/CommonBreadcrumbs";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchAboutusFooterDertails, fetchSlider } from "../../../api/API";
import InnerpageLoader from "../../Home/InnerpageLoader";
import "../Founder.css";
import FullpageLoader from "../../../common/HomeSliderLoader/FullpageLoader";

const PrarthnaMandir = () => {
  const params = useParams();
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    { label: "About Us", url: "/about-us" },
    <label color="text.primary" className="active-link-color">
      {params.title}
    </label>,
  ];

  console.log("mandirId", params.id);
  const navigate = useNavigate();

  const handleClickYagnshala = () => {
    navigate("/about-us/yagnshala");
  };

  const handleClickGaushala = () => {
    navigate("/about-us/gaushala");
  };

  const handleClickVidhyalaya = () => {
    navigate("/about-us/brahmanand-vidhyalaya");
  };

  const handleClickHostel = () => {
    navigate("/about-us/hostel");
  };

  const handleClick = (id, title) => {
    navigate(`/about-us/${title}/${id}`);
    console.log("ididid", id);
  };
  const [aboutusDetails, setAboutusDetails] = useState([]);
  const [aboutusImage, setAboutusImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAboutusFooterDertails({
          url: process.env.REACT_APP_API_URL,
          page: "about us",
          id: params.id,
        });
        setAboutusDetails(response.data.responseBody.about_us_footer_details);
        setAboutusImage(response.data.responseBody.about_us_footer);
        console.log(
          "response..",
          response.data.responseBody.about_us_footer_details
        );
        console.log(
          "responseImage",
          response.data.responseBody.about_us_footer
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [params.id]);

  useEffect(() => {
    const fetchBanner = async () => {
      setLoading(true);
      try {
        const response = await fetchSlider({
          url: process.env.REACT_APP_API_URL,
          page: "about us",
        });
        setBanner(response.data.responseBody);
        console.log("responsebanner", response.data.responseBody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div className="contact-img-wrap">
        <div className="spinner-container-banner">
          <InnerpageLoader
            src={banner}
            className="about-img"
            onImageLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="breadcrumbs-wrap">
            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
          </div>
        )}
      </div>
      {!imageLoaded && <FullpageLoader />}
      <Container className="about-us-detail-page">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={12}>
            <div className="container about-container about-us-content">
              <div>
                <span className="span-bg-color">{aboutusDetails.title}</span>
              </div>

              <Container>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <img
                      src={aboutusDetails.image}
                      alt=""
                      className="founder-img"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={9}>
                    <p
                      className="mt-sm-0 mt-md-5 mt-lg-5"
                      dangerouslySetInnerHTML={{
                        __html: aboutusDetails.description,
                      }}
                    ></p>
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="aboutUs-bottom-img-container"
        >
          {aboutusImage.map((item, index) => (
            <Grid xs={12} sm={12} md={4} lg={2} className="aboutUs-image-wrap">
              <div
                key={index}
                className="aboutUs-image-inner-wrap"
                onClick={() => handleClick(item.id, item.details.title)}
              >
                <div>
                  <img
                    src={item.details.image}
                    alt={item.details.title}
                    className="aboutus-botttom-images"
                  />
                </div>
                <div>
                  <p>{item.details.title}</p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default PrarthnaMandir;
