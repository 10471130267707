import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FaSearchPlus } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import imageNotFound from "../../src/assets/images/NoImageFound.jpg.png";
import { useLocation } from "react-router-dom";

const Images = ({ publicatioAlbumnData }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  // const handleDownload = (publication) => {
  //   const media = JSON.parse(publication.media);
  //   media.forEach((mediaUrl) => {
  //     fetch(mediaUrl)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const url = URL.createObjectURL(blob);
  //         const anchor = document.createElement("a");
  //         anchor.href = url;
  //         anchor.download = "";
  //         anchor.target = "_blank";
  //         anchor.click();

  //         URL.revokeObjectURL(url);
  //       })
  //       .catch((error) => {
  //         console.error("Error downloading media:", error);
  //       });
  //   });
  // };

  // const handleDownload = (publication) => {
  //   const imageUrl ="https://sanskardhamdev.s3.ap-south-1.amazonaws.com/common/daily_events/2024/05/12/photo_2024-05-12_12-40-21.jpg";
  // // const imageUrl ="https://sanskardhamdev.s3.ap-south-1.amazonaws.com/common/publication/media/rupala_123.jpg"
  // // const media = JSON.parse(publication.media);
  //   fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const anchor = document.createElement("a");
  //       anchor.href = url;
  //       anchor.download = "";
  //       anchor.target = "_blank";
  //       anchor.click();
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading media:", error);
  //     });  q
  // };

  // ///////
  // const handleDownload = (e, i) => {
  //   const imageUrl = JSON.parse(e.media);
  //   console.log("imageUrl",imageUrl)
  //   fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const anchor = document.createElement("a");
  //       anchor.href = url;
  //       anchor.download = "";
  //       anchor.target = "_blank";
  //       anchor.click();

  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading media:", error);
  //     });
  // };

  //1
  // const handleDownload = (e, i) => {
  //   const imageUrl = JSON.parse(e.media);
  //   console.log("imageUrl", imageUrl);
  //   fetch(imageUrl, { mode: "no-cors" })
  //     .then((response) => {
  //       if (!response.ok) throw new Error("Network response was not ok");
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const anchor = document.createElement("a");
  //       anchor.href = url;
  //       anchor.download = "";
  //       anchor.target = "_blank";
  //       anchor.click();

  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading media:", error);
  //     });
  // };

  //2
  // const handleDownload = (e, i) => {
  //   const mediaArray = JSON.parse(e.media);
  //   const imageUrl = mediaArray[0];
  //   console.log("imageUrl", imageUrl);
  //   const proxyUrl = `http://localhost:3000/proxy?url=${encodeURIComponent(
  //     imageUrl
  //   )}`;
  //   fetch(proxyUrl)
  //     .then((response) => {
  //       if (!response.ok) throw new Error("Network response was not ok");
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const anchor = document.createElement("a");
  //       anchor.href = url;
  //       anchor.download = "";
  //       anchor.target = "_blank";
  //       anchor.click();

  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading media:", error);
  //     });
  // };

  // working - chrome
  // const handleDownload = (e, i) => {
  //   const imageUrl = JSON.parse(e.media);
  //   console.log("imageUrl", imageUrl);
  //   fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const anchor = document.createElement("a");
  //       anchor.href = url;
  //       anchor.download = "";
  //       anchor.target = "_blank";
  //       anchor.click();

  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading media:", error);
  //     });
  // };

  // workking - chrome incognito
  // const handleDownload = async (e, i) => {
  //   try {
  //     const imageUrlArray = JSON.parse(e.media);
  //     const imageUrl = imageUrlArray[0];
  //     console.log("imageUrl", imageUrl);

  //     const response = await fetch(imageUrl, { mode: "cors" });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const blob = await response.blob();
  //     const url = URL.createObjectURL(blob);

  //     const anchor = document.createElement("a");
  //     anchor.href = url;
  //     anchor.download = imageUrl.split("/").pop(); // Use the filename from the URL
  //     anchor.target = "_blank";

  //     document.body.appendChild(anchor); // Append to body to ensure it's clickable
  //     anchor.click();

  //     document.body.removeChild(anchor); // Remove it after clicking
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading media:", error);
  //   }
  // };

  const handleDownload = async (e, i) => {
    try {
      const imageUrlArray = JSON.parse(e.media);
      const imageUrl = imageUrlArray[0];
      console.log("imageUrl", imageUrl);

      // Add a cache-busting query parameter
      const cacheBustingUrl = `${imageUrl}?t=${new Date().getTime()}`;

      const response = await fetch(cacheBustingUrl, { mode: "cors" });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = imageUrl.split("/").pop(); // Use the filename from the URL
      anchor.target = "_blank";

      document.body.appendChild(anchor); // Append to body to ensure it's clickable
      anchor.click();

      document.body.removeChild(anchor); // Remove it after clicking
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading media:", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const is_open_wallpaper = params.get("is_open_wallpaper");
    console.log("is_open_wallpaper", is_open_wallpaper);
    if (is_open_wallpaper && publicatioAlbumnData) {
      console.log("idOfpdf", is_open_wallpaper);
      const index = publicatioAlbumnData.findIndex(
        (item) => item.id.toString() === is_open_wallpaper
      );
      console.log("is_open_wallpaper", is_open_wallpaper);
      if (index !== -1) {
        // handleView(
        //   JSON.parse(book.media)[0],
        //   book.name,
        //   book.short_description,
        //   book.publications_id
        // );
        setPhotoIndex(index);
        setIsOpen(true);

        // Parse the current search parameters from the location
        const searchParams = new URLSearchParams(location.search);

        // Remove the 'is_open_wallpaper' parameter
        searchParams.delete("is_open_wallpaper");

        // Construct the new search string
        const newSearch = searchParams.toString();

        // Construct the new pathname with the updated search parameters
        const newPathname = location.pathname;

        // Combine the new pathname and search string to create the new URL
        const newUrl = `${newPathname}${newSearch ? "?" + newSearch : ""}`;

        // Navigate to the new URL
        //  history.push();

        window.history.pushState(null, "", newUrl);
      }
    }
  });

  return (
    <div className="pt-4">
      <Grid container spacing={2}>
        {Array.isArray(publicatioAlbumnData) &&
          publicatioAlbumnData.length > 0 &&
          publicatioAlbumnData.map((item, index) => {
            const mediaArray = item.media ? JSON.parse(item.media) : null;
            const thumbMediaArray = item.thumb_media
              ? JSON.parse(item.thumb_media)
              : null;
            return (
              <>
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <div
                    key={index}
                    className="wallpaper-content"
                    style={{ width: "100%" }}
                  >
                    <div
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    >
                      <img
                        alt=""
                        src={thumbMediaArray ? thumbMediaArray : imageNotFound}
                        className="wallpaper-img"
                      />
                      <div className="search-icon">
                        <FaSearchPlus />
                      </div>
                    </div>

                    <div className="image-details">
                      <h4 className="wallpaper-name">{item.name}</h4>
                    </div>
                    {/* <button onClick={() => handleDownload(item)}>
                      Download
                    </button> */}
                    <button
                      onClick={() => handleDownload(item)}
                      className="mt-2"
                    >
                      Download
                    </button>
                  </div>
                </Grid>
              </>
            );
          })}
        {isOpen && (
          <Lightbox
            mainSrc={JSON.parse(publicatioAlbumnData[photoIndex].media)}
            nextSrc={
              publicatioAlbumnData[
                (photoIndex + 1) % publicatioAlbumnData.length
              ].media
            }
            prevSrc={
              publicatioAlbumnData[
                (photoIndex + publicatioAlbumnData.length - 1) %
                  publicatioAlbumnData.length
              ].media
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + publicatioAlbumnData.length - 1) %
                  publicatioAlbumnData.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % publicatioAlbumnData.length)
            }
          />
        )}
      </Grid>
    </div>
  );
};

export default Images;
